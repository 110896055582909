import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
//import { makeStyles } from 'tss-react/mui';
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import throttle from "lodash/throttle";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import useMediaQuery from "@mui/material/useMediaQuery";
//import { useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Spinner from "../Spinner";
//import { selectedRowsCountSelector } from "@material-ui/data-grid";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import { AlertContext } from "../../../context/AlertContext";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { NumericFormat } from "react-number-format";
import { Toast } from "primereact/toast";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../../assets/css/DialogDemo.css";
import { Tag } from "primereact/tag";
import ClearIcon from "@mui/icons-material/Clear";
import { TextareaAutosize } from "@mui/base";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  useMap,
  latLngBound,
} from "react-leaflet";
import "./Map.css";
import { styled } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";

const PREFIX = "DatosServicio";
const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    margin: "1vw",
  },
}));

export default function DatosServicio(props) {
  //const classes = useStyles();
  const [afiliadoCartera, guardarAfiliadoCartera] = useState(null);
  const [iniciarCursor, setIniciarCursor] = useState(0);
  const [coseguro, setCoseguro] = useState(0);
  const toast = useRef(null);
  const toastTL = useRef(null);
  const toastBL = useRef(null);
  const [position, setPosition] = useState(null);

  const serviciosContext = useContext(ServiciosContext);
  const {
    afiliado,
    titular,
    direcciones,
    guardarAfiliado,
    guardarTomaAzul,
    guardarTomaNaranja,
    setOpenModalAzul,
    setOpenModalNaranja,
    cantidadServicios,
    obtenerDirecciones,
    obtenerMapa,
  } = serviciosContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    tope,
    adicionales,
    antecedentes,
    tratamientos,
    motivosConsultas,
    coseguroServicio,
    parametros,
    configuracion,
    coseguroAbonar,
    obtenerCoseguroServicio,
    guardarCoseguroServicio,
    guardarCoseguroAbonar,
  } = configuracionContext;

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;
  let navigate = useNavigate();

  //const classes = useStyles({color: afiliado ? afiliado.motivoConsultaColor : null });

  if (coseguroServicio !== null) {
    toast.current.show({
      severity: "info",
      summary:
        coseguroServicio.mensaje + " Consulta: " + coseguroServicio.servicio,
      detail: "Valor Coseguro: " + coseguroServicio.cartel,
      sticky: false,
      life: 5000,
    });
    guardarCoseguroServicio(null);
  }

  useEffect(() => {
    //mostrarDomicilio();
    mostrarAlertaTopes();
    setTimeout(() => {
      mostrarObservaciones();
    }, 1000);
    setTimeout(() => {
      mostrarAdicionales();
    }, 1000);
  }, []);

  function clearLocalidad() {
    guardarAfiliado({
      ...afiliado,
      localidad: "",
    });
  }

  //Dialog

  //const theme = useTheme();
  //const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [checkedGeo, setCheckedGeo] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const mostrarObservaciones = () => {
    if (
      titular &&
      titular.observacionesgenerales !== null &&
      titular.observacionesgenerales.trim() !== "" &&
      titular.observacionesgenerales.length > 1
    ) {
      toast.current.show({
        severity: "info",
        sticky: true,
        closable: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Observaciones`}</h4>
              <h6>{titular.observacionesgenerales}</h6>
            </div>
          </div>
        ),
      });
    }
  };

  const mostrarAlertaTopes = () => {
    if (tope !== null && cantidadServicios > tope.cantidad) {
      guardarAfiliado({
        ...afiliado,
        tope: true,
      });

      toastTL.current.show({
        severity: "error",
        sticky: true,
        closable: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Aviso`}</h4>
              <h6>{`El afiliado ${tope.titularId}-${tope.nombre} ha superado el tope de servicios. (${cantidadServicios}/${tope.cantidad})`}</h6>
            </div>
          </div>
        ),
      });
    } else {
      guardarAfiliado({
        ...afiliado,
        tope: false,
      });
    }
  };

  const mostrarAdicionales = () => {
    if (adicionales !== null) {
      toastTL.current.show({
        severity: "error",
        sticky: true,
        closable: true,
        content: (
          <div
            className="p-flex p-flex-column"
            style={{ flex: "1", marginLeft: "1em" }}
          >
            <div className="p-text-center">
              <i
                className="pi pi-exclamation-triangle"
                style={{ fontSize: "3rem" }}
              ></i>
              <h4>{`Aviso`}</h4>
              <h6>{`El afiliado ${adicionales.id_titular} posee módulo MAD.`}</h6>
            </div>
          </div>
        ),
      });
    }
  };

  const mostrarDomicilio = () => {
    toast.current.show({
      severity: "info",
      sticky: true,
      closable: true,
      content: (
        <div
          className="p-flex p-flex-column"
          style={{ flex: "1", marginLeft: "1em" }}
        >
          <div className="p-text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <h4>{`Domicilio`}</h4>
            <h6>{afiliado.domicilio}</h6>
          </div>
        </div>
      ),
    });
  };

  // Leer tecla y foco

  const handleInputBlur = (event) => {
    if (event.relatedTarget.id === "provincia") {
      return;
    }

    /*    
    const close = document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];

    setTimeout(() => {
      if (close) {
        close.click();
      }
    }, 100);
    */

    clearLocalidad();
    obtenerDirecciones({
      domicilio: afiliado.domicilio.toLowerCase(),
      provincia: afiliado.provincia.toLowerCase(),
    });
  };

  function onSearchtermChange(value) {
    console.log(value);
    return;
    /*
    if (event.keyCode === 13) {
      obtenerDirecciones({ domicilio: afiliado.domicilio.toLowerCase() });
    }
    */
  }

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "dni":
          campo = document.getElementById("nombre");
          campo.focus();
          break;
        case "nombre":
          campo = document.getElementById("telefono");
          campo.focus();
          break;
        case "telefono":
          campo = document.getElementById("provincia");
          campo.focus();
          break;
        case "provincia":
          campo = document.getElementById("domicilio");
          campo.focus();
          break;
        case "domicilio":
          campo = document.getElementById("domicilioGeo");
          campo.focus();
          break;
        case "domicilioGeo":
          campo = document.getElementById("entre");
          campo.focus();
          break;
        case "entre":
          campo = document.getElementById("localidad");
          campo.focus();
          break;
        case "localidad":
          campo = document.getElementById("edad");
          campo.focus();
          break;
        case "edad":
          campo = document.getElementById("sexo");
          campo.focus();
          break;
        case "sexo":
          campo = document.getElementById("antecedente");
          campo.focus();
          break;
        case "antecedente":
          campo = document.getElementById("tratamiento");
          campo.focus();
          break;
        case "tratamiento":
          campo = document.getElementById("motivoConsulta");
          campo.focus();
          break;
        case "motivoConsulta":
          campo = document.getElementById("operadorEmisor");
          campo.focus();
          break;
        case "operadorEmisor":
          campo = document.getElementById("indicativoEmisor");
          campo.focus();
          break;
        case "indicativoEmisor":
          campo = document.getElementById("obraSocial");
          campo.focus();
          break;
        case "obraSocial":
          campo = document.getElementById("nroObraSocial");
          campo.focus();
          break;
        case "nroObraSocial":
          campo = document.getElementById("coseguro");
          campo.focus();
          break;
        case "coseguro":
          campo = document.getElementById("covid");
          campo.focus();
          break;
        case "covid":
          campo = document.getElementById("fueraZona");
          campo.focus();
          break;
        case "fueraZona":
          campo = document.getElementById("celular");
          campo.focus();
          break;
        case "videollamada":
          campo = document.getElementById("observaciones");
          campo.focus();
          break;
        case "celular":
          campo = document.getElementById("videollamada");
          campo.focus();
          break;
      }
    }

    if (event.keyCode === 27) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "nombre":
          campo = document.getElementById("dni");
          campo.focus();
          break;
        case "telefono":
          campo = document.getElementById("nombre");
          campo.focus();
          break;
        case "provincia":
          campo = document.getElementById("telefono");
          campo.focus();
          break;
        case "domicilio":
          campo = document.getElementById("provincia");
          campo.focus();
          break;
        case "domicilioGeo":
          campo = document.getElementById("domicilio");
          campo.focus();
          break;
        case "entre":
          campo = document.getElementById("domicilioGeo");
          campo.focus();
          break;
        case "localidad":
          campo = document.getElementById("entre");
          campo.focus();
          break;
        case "edad":
          campo = document.getElementById("localidad");
          campo.focus();
          break;
        case "sexo":
          campo = document.getElementById("edad");
          campo.focus();
          break;
        case "antecedente":
          campo = document.getElementById("sexo");
          campo.focus();
          break;
        case "tratamiento":
          campo = document.getElementById("antecedente");
          campo.focus();
          break;
        case "motivoConsulta":
          campo = document.getElementById("tratamiento");
          campo.focus();
          break;
        case "operadorEmisor":
          campo = document.getElementById("motivoConsulta");
          campo.focus();
          break;
        case "indicativoEmisor":
          campo = document.getElementById("operadorEmisor");
          campo.focus();
          break;
        case "obraSocial":
          campo = document.getElementById("indicativoEmisor");
          campo.focus();
          break;
        case "nroObraSocial":
          campo = document.getElementById("obraSocial");
          campo.focus();
          break;
        case "covid":
          campo = document.getElementById("coseguro");
          campo.focus();
          break;
        case "coseguro":
          campo = document.getElementById("nroObraSocial");
          campo.focus();
          break;
        case "fueraZona":
          campo = document.getElementById("covid");
          campo.focus();
          break;
        case "videollamada":
          campo = document.getElementById("fueraZona");
          campo.focus();
          break;
        case "celular":
          campo = document.getElementById("videollamada");
          campo.focus();
          break;
        case "observaciones":
          campo = document.getElementById("celular");
          campo.focus();
          break;
      }
    }
  }

  let campo = null;
  if (afiliado && titular && iniciarCursor === 0) {
    if (afiliado.id === titular.id && afiliado.grupoId !== 1) {
      campo = document.getElementById("dni");
      if (campo) {
        guardarAfiliadoCartera(false);
        setIniciarCursor(1);
        guardarAfiliado({
          ...afiliado,
          id: "00000000000000000000",
          nombre: "",
          dni: "",
          telefono: "",
          edad: "",
          sexo: null,
          antecedenteId: null,
          antecedenteNombre: "",
          tratamientoId: null,
          tratamientoNombre: "",
          motivoConsultaId: null,
          motivoConsultaNombre: "",
        });
      }
    } else {
      campo = document.getElementById("dni");
      if (campo) {
        guardarAfiliadoCartera(true);
        setIniciarCursor(1);
      }
    }
  }

  if (iniciarCursor === 1) {
    let campo = null;
    if (afiliadoCartera) {
      campo = document.getElementById("dni");
    } else {
      campo = document.getElementById("dni");
    }
    campo.focus();
    setIniciarCursor(2);
  }

  const displayCoseguro = (value, consulta) => {
    //console.log(afiliado, value)
    let index = motivosConsultas.findIndex((item) => item.id === value);
    let servicioId = motivosConsultas[index].servicioId;
    let servicioNombre = motivosConsultas[index].nombre;

    obtenerCoseguroServicio({
      servicio: servicioId,
      afiliado,
      titular,
      nombre: servicioNombre,
    });
  };

  const verificarPantalla = (color, traslado) => {
    if (traslado) {
      setTimeout(() => {
        guardarTomaAzul({
          retorno: false,
          espera: 0,
          provincia: "Buenos Aires",
        });
        setOpenModalAzul(true);
      }, 2000);
    }
    if (color === "NARANJA") {
      setTimeout(() => {
        guardarTomaNaranja({
          contacto: "",
          telefono: "",
          cantidadMoviles: 1,
          dotacion: "",
          tipoEvento: "",
          descripcion: "",
          cantidadPersonas: "",
          responsable: "",
        });
        setOpenModalNaranja(true);
      }, 2000);
    }
  };

  const renderHeaderMapa = () => {
    return (
      <div>
        <Tag value="Ubicación en el Mapa"></Tag>
      </div>
    );
  };

  const renderFooterMapa = () => {
    return (
      <div>
        <Button
          label="Cerrar"
          icon="pi pi-times"
          onClick={() => handleClose()}
          className="p-button-text"
        />
      </div>
    );
  };

  function PopupExample(e) {
    if (
      afiliado.lat === undefined ||
      afiliado.lat === null ||
      afiliado.lon === undefined ||
      afiliado.lon === null
    ) {
      return;
    }

    let position = [];
    position.push(afiliado.lat);
    position.push(afiliado.lon);

    return (
      <MapContainer
        center={position}
        zoom={15}
        style={{ height: "60vh", width: "60vh" }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}></Marker>
      </MapContainer>
    );
  }

  //console.log(afiliado.telefono);

  return (
    <Root className={classes.root}>
      <Fragment>
        {afiliado && antecedentes && tratamientos && motivosConsultas ? (
          <Fragment>
            <Toast ref={toast} />
            <Toast ref={toastTL} position="top-left"></Toast>
            <Toast ref={toastBL} position="bottom-left"></Toast>
            <Grid container spacing={2} style={{ padding: "0" }}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="titular"
                  label="Titular"
                  value={
                    afiliado && titular
                      ? afiliado.titularId + " - " + titular.nombre_razonsocial
                      : null
                  }
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  disabled={true}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="plan"
                  label="Plan"
                  value={afiliado ? afiliado.plan : null}
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  disabled={true}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  id="id"
                  label="ID"
                  value={afiliado ? afiliado.id : null}
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  disabled={true}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <NumericFormat
                  {...props}
                  id="dni"
                  label="D.N.I."
                  customInput={TextField}
                  format="########"
                  type="numeric"
                  value={afiliado ? afiliado.dni : null}
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  size="small"
                  //disabled={afiliadoCartera ? true : false}
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      dni: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  id="nombre"
                  label="Apellido y Nombre"
                  value={afiliado ? afiliado.nombre : null}
                  required
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  //disabled={afiliadoCartera ? true : false}
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      nombre: e.target.value.toUpperCase(),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  id="telefono"
                  label="Teléfono"
                  value={afiliado ? afiliado.telefono : null}
                  required
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      telefono: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <TextField
                  id="provincia"
                  label="Provincia"
                  value={afiliado ? afiliado.provincia : null}
                  required
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      provincia: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  id="domicilio"
                  label="Dirección"
                  value={afiliado ? afiliado.domicilio : null}
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  onKeyDown={handleEnter}
                  onBlur={(e) => handleInputBlur(e)}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      domicilio: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Autocomplete
                  id="domicilioGeo"
                  fullWidth
                  noOptionsText={"No hay opciones"}
                  options={direcciones}
                  getOptionLabel={(option) => option.label}
                  openOnFocus={true}
                  disablePortal
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Dirección Geolocalizada"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      guardarAfiliado({
                        ...afiliado,
                        domicilioGeo: value.label,
                        localidadGeo: value.localidad.toUpperCase(),
                        localidad:
                          value.localidad !== ""
                            ? value.localidad.toUpperCase() +
                              "-" +
                              value.provincia.toUpperCase()
                            : value.provincia.toUpperCase(),
                        lat: value.lat,
                        lon: value.lon,
                      });
                      campo = document.getElementById("entre");
                      campo.focus();
                    } else {
                      guardarAfiliado({
                        ...afiliado,
                        domicilioGeo: null,
                        localidadGeo: null,
                        lat: null,
                        lon: null,
                      });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={4} md={1}>
                <IconButton
                  type="submit"
                  aria-label="search"
                  onClick={(e) => handleClickOpen()}
                >
                  <SearchIcon />
                </IconButton>
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id="entre"
                  label="Entre calles"
                  value={afiliado ? afiliado.entreCalles : null}
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      entreCalles: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  id="localidad"
                  label="Localidad"
                  value={afiliado.localidad}
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  //disabled={afiliadoCartera ? true : false}
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      localidad: e.target.value.toUpperCase(),
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={1}>
                <NumericFormat
                  {...props}
                  id="edad"
                  label="Edad"
                  customInput={TextField}
                  format="###"
                  type="numeric"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  required
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      edad: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={1}>
                <FormControl fullWidth onKeyDown={handleEnter}>
                  <InputLabel id="simple-select-label-sexo">Sexo</InputLabel>
                  <Select
                    labelId="Sexo"
                    id="sexo"
                    required
                    value={afiliado ? afiliado.sexo : null}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      guardarAfiliado({
                        ...afiliado,
                        sexo: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={1}>M</MenuItem>
                    <MenuItem value={2}>F</MenuItem>
                  </Select>{" "}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  id="antecedente"
                  fullWidth
                  required
                  noOptionsText={"No hay opciones"}
                  options={antecedentes}
                  getOptionLabel={(option) => option.nombre}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Antecedente"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      guardarAfiliado({
                        ...afiliado,
                        antecedenteId: value.id,
                        antecedenteNombre: value.nombre,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  id="tratamiento"
                  fullWidth
                  disablePortal
                  required
                  noOptionsText={"No hay opciones"}
                  options={tratamientos}
                  getOptionLabel={(option) => option.nombre}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tratamiento"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      guardarAfiliado({
                        ...afiliado,
                        tratamientoId: value.id,
                        tratamientoNombre: value.nombre,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={11} md={5}>
                <Autocomplete
                  id="motivoConsulta"
                  fullWidth
                  required
                  disablePortal
                  debug
                  noOptionsText={"No hay opciones"}
                  options={
                    configuracion &&
                    configuracion.destinatario === 1 &&
                    afiliado &&
                    (afiliado.planId === 38 || afiliado.planId === 39)
                      ? motivosConsultas.filter(
                          (element) =>
                            element.color === "ROJO" ||
                            element.color === "VERDE"
                        )
                      : motivosConsultas
                  }
                  //classes={classes }

                  getOptionLabel={(option) => option.nombre}
                  /*
                  renderOption={(props, option: AcOption) => (
                    <li {...props}>{option.code}&nbsp;&nbsp;&nbsp;{option.description}</li>
                  )}
                  */

                  renderOption={(props, option) => {
                    const { nombre, codColor } = option;
                    return (
                      <li
                        {...props}
                        style={{
                          backgroundColor: codColor,
                          color:
                            codColor === "#FFFF00" ||
                            codColor === "#FFFFFF" ||
                            codColor === "#00FFFF"
                              ? "#000000"
                              : "#FFFFFF",
                        }}
                      >
                        {nombre}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Motivo de consulta"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      //console.log(value);
                      guardarAfiliado({
                        ...afiliado,
                        motivoConsultaId: value.id,
                        motivoConsultaNombre: value.nombre,
                        motivoConsultaCodigoColor: value.codColor,
                        motivoConsultaColor: value.color,
                        motivoConsultaTraslado: value.traslado,
                      });
                      guardarCoseguroServicio(null);
                      displayCoseguro(value.id);
                      verificarPantalla(value.color, value.traslado);
                    } else {
                      guardarAfiliado({
                        ...afiliado,
                        motivoConsultaId: null,
                        motivoConsultaNombre: null,
                        motivoConsultaCodigoColor: null,
                        motivoConsultaColor: null,
                        motivoConsultaTraslado: null,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={1} md={1}>
                <Button
                  size="normal"
                  variant="outlined"
                  style={{
                    marginTop: "0.5em",
                    backgroundColor: afiliado.motivoConsultaCodigoColor
                      ? afiliado.motivoConsultaCodigoColor
                      : "#FFF",
                    color:
                      afiliado.motivoConsultaColor === "AMARILLO" ||
                      afiliado.motivoConsultaColor === "CELESTE" ||
                      afiliado.motivoConsultaColor === "BLANCO"
                        ? "#000000"
                        : "#FFFFFF",
                  }}
                >
                  {afiliado && afiliado.motivoConsultaColor !== null
                    ? afiliado.motivoConsultaColor
                    : "Color"}
                </Button>
              </Grid>

              <Grid item xs={6} md={3}>
                <TextField
                  id="operadorEmisor"
                  label="Operador emisor"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      operadorEmisor: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  id="indicativoEmisor"
                  label="Indicativo emisor"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      indicativoEmisor: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={2.5}>
                <TextField
                  id="obraSocial"
                  label="Obra social/Convenio"
                  fullWidth
                  variant="standard"
                  size="small"
                  InputLabelProps={{ style: { fontSize: 14 }, shrink: true }} // font size of input label
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      obraSocial: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={6} md={1.5}>
                <TextField
                  id="nroObraSocial"
                  label="Nro. Obra social/Convenio"
                  fullWidth
                  variant="standard"
                  size="small"
                  InputLabelProps={{ style: { fontSize: 12, shrink: true } }} // font size of input label
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      nroObraSocial: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={6} md={1}>
                <TextField
                  id="coseguro"
                  label="Coseguro"
                  fullWidth
                  variant="standard"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onKeyDown={handleEnter}
                  value={coseguroAbonar}
                  onChange={(e) => guardarCoseguroAbonar(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={1} style={{ paddingLeft: 0 }}>
                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      id="covid"
                      color="primary"
                      checked={afiliado ? afiliado.covid : false}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarAfiliado({
                          ...afiliado,
                          covid: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Covid"
                  labelPlacement="start"
                />
              </Grid>

              <Grid item xs={12} md={2} style={{ paddingLeft: 0 }}>
                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      id="fueraZona"
                      color="primary"
                      checked={afiliado ? afiliado.fueraZona : false}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarAfiliado({
                          ...afiliado,
                          fueraZona: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Fuera de Zona"
                  labelPlacement="start"
                />
              </Grid>

              <Grid item xs={12} md={1.5} style={{ paddingLeft: 0 }}>
                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      id="videollamada"
                      color="primary"
                      checked={afiliado ? afiliado.videollamada : false}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarAfiliado({
                          ...afiliado,
                          videollamada: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Videollamada"
                  labelPlacement="start"
                />
              </Grid>

              <Grid item xs={12} md={1.5}>
                <TextField
                  id="celular"
                  label="WhatsApp"
                  value={afiliado ? afiliado.celular : null}
                  required
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      celular: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={1.5} style={{ paddingLeft: 0 }}>
                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      id="rederivado"
                      color="primary"
                      checked={afiliado ? afiliado.rederivado : false}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarAfiliado({
                          ...afiliado,
                          rederivado: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Rederivado"
                  labelPlacement="start"
                />
              </Grid>

              <Grid item xs={12} md={1.5}>
                <TextField
                  id="indicativoRederivado"
                  label="Ind. Rederivado"
                  value={afiliado ? afiliado.indicativoRederivado : null}
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      indicativoRederivado: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextareaAutosize
                  id="observaciones"
                  maxRows={5}
                  minRows={5}
                  //value={afiliado.observaciones}
                  aria-label="maximum height"
                  placeholder="Observaciones"
                  defaultValue=""
                  style={{ width: "-webkit-fill-available", marginTop: ".5em" }}
                  /*
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      observaciones: e.target.value,
                    })
                  }
                  */
                />
              </Grid>

              <Grid item xs={12} md={1.5} style={{ paddingLeft: 0 }}>
                <FormControlLabel
                  value="yes"
                  control={
                    <Checkbox
                      id="simultaneo"
                      color="primary"
                      checked={afiliado ? afiliado.simultaneo : false}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarAfiliado({
                          ...afiliado,
                          simultaneo: e.target.checked,
                          cantidadSimultaneo: e.target.checked ? 2 : "",
                        })
                      }
                    />
                  }
                  label="Simultáneo"
                  labelPlacement="start"
                />
              </Grid>

              <Grid item xs={12} md={1.5}>
                <TextField
                  id="cantidadSimultaneo"
                  label="Cant. Simultáneo"
                  value={afiliado ? afiliado.cantidadSimultaneo : null}
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  type="number"
                  disabled={!afiliado.simultaneo}
                  //onKeyDown={handleEnter}
                  onChange={(e) =>
                    guardarAfiliado({
                      ...afiliado,
                      cantidadSimultaneo:
                        e.target.value <= 1 ? 2 : e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={12}></Grid>
            </Grid>
            <div className="dialog-demo">
              <Dialog
                header={renderHeaderMapa()}
                visible={open}
                footer={renderFooterMapa()}
                closable={false}
              >
                {PopupExample()}
              </Dialog>
            </div>
          </Fragment>
        ) : (
          <Spinner />
        )}
      </Fragment>
    </Root>
  );
}
